import React, {useContext,useState}   from 'react'
import { keyContext } from "./KeyContext";
import moment from "moment";


function DagenTable({omsError, consession, otherSale, otherError}) {
  const { dagensData } = useContext(keyContext);
  const theme = localStorage.getItem('theme')
  const key = sessionStorage.getItem("key");



  let filmPerFors = [];
  let filmName = [];
  let start = [];
  let sal = [];
  let end = [];
  let res = [];
  let sold = [];
  let totl = [];
  let bel = [];
  let oms = [];
  let datoer = [];
  let seatCount = [];
  let Reserveret = 0;
  let solgte = 0;
  let total = 0;
  let totalBel = 0;
  let totalOms = 0;
  let kiosOms = Number(consession);
  let andenOms = Number(otherSale)
  let totalSoldAmount = 0;

  dagensData&&
  dagensData.map((item) => {
      filmName.push(item.HomeTeamName);
      seatCount.push(item.TotalSeatCount);
      datoer.push(item.ArrTime.slice(0, 10));
      oms.push(Math.round(item.TotalSoldAmount));
      bel.push(
        Math.round(
          ((item.TotalResCount + item.TotalSoldCount) * 100) /
            item.TotalSeatCount
        )
      );
      totl.push(item.TotalResCount + item.TotalSoldCount);
      res.push(item.TotalResCount);
      sold.push(item.TotalSoldCount);
      start.push(item.ArrTime.slice(11, 16));
      sal.push(item.LocName);
      end.push(
        moment()
          .hour(item.ArrTime.slice(11, 13))
          .minute(item.ArrTime.slice(14, 16))
          .add(Math.floor(item.DurationSecs / 3600), "hours")
          .add(
            Math.floor(
              (item.DurationSecs -
                Math.floor(item.DurationSecs / 3600) * 3600) /
                60
            ),
            "minutes"
          )
          .format("HH:mm")
      );
    });

    if (dagensData) {
      for (let index = 0; index < dagensData.length; index++) {
        filmPerFors.push({
          id: index,
          name: filmName[index],
          start: start[index],
          end: end[index],
          sal: sal[index],
          res: res[index],
          sold: sold[index],
          total: totl[index],
          belaeg: bel[index],
          oms: oms[index],
          dato: datoer[index],
          seat_count: seatCount[index],
        });
      }
    // setDagensData(filmPerFors)
  
    }
  // sorting of tables (works on billetsalg per forestilling table)
  const useSortTableData = (filmsItems, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...filmsItems];
      sortableItems.sort((a,b) => 
        a.start > b.start ? 1 : -1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
         
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [filmsItems, sortConfig]);

    const requestFilmSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { filmsItems: sortedItems, requestFilmSort, sortConfig };
  };
  
  const { filmsItems, requestFilmSort } = useSortTableData(filmPerFors);
 
        
  return   dagensData.length ? ( 
        <main style={{marginTop:'20px'}}>

        <p className='dagensTile' style={{color: theme === 'dark' ? '#fff' : '#46484c',fontSize:'18px',fontWeight:'bold', opacity:theme === 'dark' ? '0.6':'',height:'28px',letterSpacing:'0.36px'}}>Billetsalg pr. forestilling</p> 
       
        
        <>
    <table className={"table table-responsive " + `${theme === 'dark' ? 'table-striped table-responsive' : ''}`} style={{borderColor:theme === 'dark' ? '#6c6c6c' : ''}}>
        <thead> 
          <tr>
            <td style={{cursor:'pointer', color:'#8d8d8d',paddingLeft:0,letterSpacing:'0.32px'}} onClick={() => requestFilmSort("name")}>Film</td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("sal")}>Sal</td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("start")}>Start</td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("end")}>Slut</td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("res")}>Reserveret </td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("sold")}>Solgte</td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("total")}>Total</td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("belaeg")}>Belægning</td>
            <td style={{cursor:'pointer', color:'#8d8d8d', textAlign:'center',letterSpacing:'0.32px'}} onClick={() => requestFilmSort("oms")}>Omsætning</td>
          </tr>
        </thead>
        <tbody className='dagens_body' style={{letterSpacing:'0.4px'}}>
          {dagensData && filmsItems.map(item => {
            if(item) {
              Reserveret = Reserveret + item.res;
              solgte = solgte + item.sold;
              total = total + item.res + item.sold;
              totalOms = totalOms + item.oms;
              totalBel = totalBel +  item.seat_count;
              totalSoldAmount = totalOms + kiosOms + andenOms
              
              return (
              <>
            <tr  style={{height:'55px'}} className={`${theme === 'dark' ? '' : 'dagens_striped'}`}>
               <td  className="text-truncate" style={{ maxWidth: "300px",paddingTop:'12px', }}>{item.name}</td>
            <td className="rightAlign" style={{paddingTop:'12px',}}><p style={{margin:0,padding:0,width:'50px', marginRight:'15px'}}> {item.sal}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'55px',marginRight:'15px'}}>{item.start}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'55px',marginRight:'10px'}}>{item.end} </p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'50px',marginRight:'10px'}}>{item.res}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'40px',marginRight:'20px',border:'1px '}}>{item.sold} </p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'50px',marginRight:'20px'}}>{item.total}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'55px',marginRight:'25px'}}>{item.belaeg}%</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,marginRight:'25px'}}>{item.oms.toLocaleString('da')}</p></td>
            </tr>

            </>
            ) }
         }
          )}
        </tbody>
        <tfoot>
          <tr style={{height:'55px',fontWeight:'bold', borderTop:theme === 'dark' ? '1px solid #dee0e3': '1px solid #1a1b1e',}} >
            <td className="">{dagensData && dagensData.length}{' '}  forestillinger</td>
            <td colSpan={3}></td>
            <td className="rightAlign"><p style={{margin:0,width:'52px'}}>{Reserveret}</p></td>
            <td className="rightAlign"><p style={{margin:0,width:'42px'}}>{solgte}</p></td>
            <td className="rightAlign"><p  style={{margin:0,width:'52px'}}>{total}</p></td>
            <td className="rightAlign"><p style={{margin:0,width:'55px'}}>{Math.round((total * 100) / totalBel)}%</p></td>
            <td className="rightAlign"><p style={{height:'20px',paddingTop:'5px',paddingRight:'25px'}}>{Math.round(totalOms).toLocaleString('da')}</p></td>
          </tr>
          <tr style={{backgroundColor: theme === 'dark' ? '#1a1b1e':'#f7f7f7'}}>
            <td style={{height:'75px',lineHeight:'18px'}}> Kioskomsætning <br></br>
              <span style={{fontSize:'14px',opacity:0.6}}>Kioskomsætning pr. solgt billet: {''}
              { omsError ? '0' : (Math.round((consession/total)*100)/100).toLocaleString('da')}Kr.
              </span>
           </td>
           <td colspan={7}></td>
           <td className="rightAlign" style={{backgroundColor: theme === 'dark' ? '#242527' :'#fff',color: omsError ? 'red' : ''}}><p style={{bottom:'18px',fontWeight:'bold',height:'20px',paddingTop:'5px',paddingRight:'25px'}}>
            {omsError ? '*':  Math.round(kiosOms).toLocaleString('da')}
            </p></td>
          </tr>
          <tr style={{height:'55px',backgroundColor: theme === 'dark' ? '#1a1b1e':'#f7f7f7'}}>
            <td style={{}}>Anden omsætning</td>
            <td colspan={7}></td>
            <td className="rightAlign" style={{backgroundColor: theme === 'dark' ? '#242527' :'#fff',color:otherError ? 'red':'',fontWeight:'bold'}}><p style={{fontWeight:'bold',height:'20px',paddingTop:'5px',paddingRight:'25px'}}>
              {otherError ? '*' : Math.round(andenOms).toLocaleString('da')}
              </p></td>
          </tr>
          <tr style={{height:'55px',borderBottom:theme === 'dark' ? '1px solid #dee0e3':'1px solid #1a1b1e',backgroundColor: theme === 'dark' ? '#1a1b1e':'#f7f7f7',fontWeight:'bold'}}>
            <td style={{color:theme === 'dark' ? '#ecc590' : '#101010',}}>Samlet omsætning</td>
            <td colspan={7}></td>
            <td className="rightAlign" style={{backgroundColor: theme === 'dark' ? '#242527' :'#fff'}}><p style={{height:'20px',paddingTop:'5px',paddingRight:'25px',color:theme === 'dark' ? '#ecc590' : '#101010'}}>{Math.round(totalSoldAmount).toLocaleString('da')}</p></td>
          </tr>
        </tfoot>
       </table>

       <p style={{color:'red'}}> {omsError || otherError}</p>
        </>
        </main>
        ) : (<p></p>) 
    
  
}

export default DagenTable